.btns-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;

  button:first-child {
    margin-right: 1rem;
  }
  @media (max-width: 475px) {
    margin: 3rem 1rem 1rem 1rem;
  }
}

.content-wrapper {
  padding: 1rem;
}
