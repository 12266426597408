.mainswiper {
  .swiper-pagination-bullet-active {
    background: #28a745;
  }
  
  .swiper {
    padding-top: 15px;
    padding-bottom: 50px;


    .swiperslide {
      width: 550px;
    //height: 350px;
      height: 0;
      padding-bottom: 28.5%;
      position: relative;
      overflow: hidden;
      img {
        display: block;
        object-fit: fill;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
}


 