.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .row {
    margin-bottom: 20px;
  }

  .buttonsRow {
    .submit {
      margin-right: 20px;
    }

    margin-top: 10px;
  }
}
