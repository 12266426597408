.error{
    color: red;
    margin-top: 0.3em;
}

.reset_password{
    padding: 1em;
    &__form{
        padding-top: 5em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__title{
        max-width: 555px;
        text-align: center;
    }
    &__inp {
        margin: 1em;
        text-align: center;
    }
    &__btn {
        color: red;
        border: 2px solid green;
    }
    &__succes{
        font-size: 1.17em;
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        position: relative;
        padding: 0.75rem 1.25rem;
        margin: 1rem;
        max-width: 555px;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }
    &__field{
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        width: 300px;
        border: 1px solid #ced4da;
        border-color: #80bdff;
        transition: .30s
    }
    &__field:focus{
        color: #495057;
        background-color: #fff;
        outline: 0;
    }
    &__invalid{
        border-color: #dc3545;
        box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
    }
    &__valid{
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%);
    }
}