.form-btn-wrapper {
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem;

  button {
    border-radius: 30px;
    &:first-child {
      margin-right: 2rem;
    }
  }
}

.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
}
