.container {
  margin: auto;
  width: 100%;
  .categories {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-right: 0.3rem;
      margin-bottom: 0.3rem;
    }
  }
  .selector {
    padding: 2rem 4rem;
  }
  .form {
    min-height: 10rem;
  }
}
