.resendMessage {
  &__block {
    width: 100%;
  }

  &__btn {
    min-width: 200px;
    color: #fff;
    border: none;
    height: 44px;
    transition: opacity 0.3s ease;
  }

  &__timerBlock {
    position: relative;
    padding-top: 2px;
  }

  &__timer {
    text-align: center;
  }
}
.spinner {
  position: absolute;
  top: -3px;
  right: 72px;
}
