.container {
  padding: 1rem;
  @media (max-width: 475px) {
    margin-top: 30px;
  }

  .header-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
}
