.eye {
  width: 20px;
  height: 20px;

  border-radius: 50%;

  transition: all 0.1s;
  &:hover {
    background-color: #26bd62;
  }
}
.form {
  &__resetPassword {
    display: flex;
    justify-content: center;
    cursor: pointer;
    text-decoration: underline;
    color: #007bff;
    transition: opacity 0.3s ease;
    span {
      margin-left: 15px;
    }
  }
}

.popoverBody {
  & > div {
    max-width: 268px;
    z-index: 9999;
    font-size: 13px;
  }
}
