.cardsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    z-index: 1;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    overflow: auto;
    border-radius: 3px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    &.pending {
      &::after {
        content: 'ОЧІКУЄТЬСЯ';
        position: absolute;
        top: 0;
        left: 0;
        color: #000;
        font-weight: bold;
        text-align: center;
        height: 25px;
        width: 15%;
        background-color: #00bfff;
        z-index: 2;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #e4e4e486;
        z-index: 1;
        pointer-events: none;
      }
    }

    img {
      width: 50px;
      display: block;
      margin: auto;
    }

    .childrensBlock {
      width: max-content;
      height: auto;
      max-height: 800px;
      margin: 35px auto 0;

      ul {
        padding-left: 0;
      }

      > div {
        display: flex;
      }
    }

    hr {
      margin-top: auto;
      width: 100%;
    }

    h5 {
      cursor: pointer;
      text-align: center;
    }

    .icons {
      display: flex;
      justify-content: center;
      column-gap: 100px;

      > :first-child {
        cursor: pointer;
        color: #26bd62;
        transition: 0.3s all;

        :hover {
          color: #1ba34f;
        }
      }

      :nth-child(2) {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.69);
        transition: 0.3s all;

        :hover {
          color: rgba(0, 0, 0, 0.99);
        }
      }

      :nth-child(3) {
        cursor: pointer;
        transition: 0.3s all;
        color: red;

        :hover {
          color: rgb(216, 0, 0);
        }
      }
    }
  }
}

.iconsDark {
  display: flex;
  justify-content: center;
  column-gap: 100px;

  > :first-child {
    cursor: pointer;
    color: #4a7c56;
    transition: 0.3s all;

    :hover {
      color: #3c6646;
    }
  }

  :nth-child(2) {
    cursor: pointer;
    color: white;
    transition: 0.3s all;

    :hover {
      color: grey;
    }
  }

  :nth-child(3) {
    cursor: pointer;
    transition: 0.3s all;
    color: #c71010;

    :hover {
      color: #ab0c0c;
    }
  }
}

.cardsContainer-dark {
  @extend .cardsContainer;

  .card {
    background-color: inherit;
  }
}
