.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > button {
    margin: 10px 0;
    border-radius: 30px;
  }
}

.formTitle {
  margin-top: 10px;
}

.form {
  display: flex;
  flex-wrap: wrap;
}

.inputContainer {
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  margin-right: 20px;
}

.input {
  width: 50%;
  border: none;
  border-bottom: 1px solid;
}

.inputDanger {
  border-color: red;
}

.errorContainer {
  height: 2rem;
}

.error {
  color: red;
  margin: auto;
  font-size: 1.5rem;
}

.text {
  margin-bottom: 0;
}

.textDanger {
  color: red;
}
