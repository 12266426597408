.page{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text{
  font-size: 30px;
  font-weight: 500;

  margin-bottom: 30px;
}