.mainForm {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .box {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    gap: 20px;
  }

  button {
    margin-top: 20px;
  }
}