.description {
  .field {
    display: flex;
    align-items: baseline;
    border-bottom: 1px solid #ddd;
    padding: 1rem;

    & p {
      margin-bottom: 0;
    }

    .title {
      font-size: 1.1rem;
      font-weight: bold;
      margin-right: 10px;
      color: #555;
    }

    .value {
      font-size: 1rem;
      font-style: italic;
    }
    
    img {
      display: block;
      margin: auto;
      width: 70px;
    }
  }
}

.description-dark {
  @extend .description;

  .field .title {
    color: #eee;
  }
}