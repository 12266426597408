.page{
    width: 100%;
    height: 100%;

    padding: 50px 10px 10px 10px; 
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow-y: auto;
}

.leftSide{
    min-width: 280px;
    position: relative;

    width: 40%;
    height: 100%;
}

.editIcon{
    position: absolute;
    top: 0;
    left: 30%;
    cursor: pointer;

    transition: all .2s;

    &:hover{
        fill: grey;
    }
}

.cancelSave{
    position: absolute;
    top: 0;
    right: 0;

    button{
        margin: 0 0 0 7px;
    }
}

.rightSide{
    min-width: 280px;
    width: 40%;
    height: 100%;

    display: flex;
    justify-content: center;
}

.wrapper{
    height: 59px;

    margin: 0 0 20px 0;
}

.grayWords{
    font-size: 14px;
    color: #a6a6a6;
}

.normalWords{
    font-size: 17px;
    font-weight: 500;
}

.input{
    height: 10px ;
}

.rightBody{
    width: 280px;
    height: 280px;
}

.imageContainer{
    position: relative;

    width: 100%;
    height: 70%;

    display: flex;
    justify-content: center;
    align-items: center;
     
    .avatarOverlay {
        width: 150px;
        height: 150px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        top: 23px;
        opacity: 0;
        pointer-events: none;
        transition: all ease 0.25s;
        cursor: pointer;
        z-index: 10;
    }

    &:hover,
    &:focus {
      .avatarOverlay {
        pointer-events: initial;
        opacity: 1;
      }
    }

    input {
        display: none;
    }
}

.possibleImgParams{
    height: 30%;
}

.possibleImgParamsText{
    font-size: 12px;
    color: #a6a6a6;
}

.deleteImg{
    position: absolute;
    top:0;
    right: 0;
    cursor: pointer;

    &:hover{
        fill: rgba(255, 0, 0, 1);
    }
}

@media (max-width: 1000px) {
    .page{
        flex-direction: column-reverse;
        padding: 50px 0 10px 0;
    }
    .leftSide{
        height: fit-content;
    }
    .rightSide{
        height: 50vh;
    }
}

@media (max-width: 480px) {
    .leftSide{
        height: fit-content;
    }
}