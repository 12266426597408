.mainForm {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .price {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: calc(50% - 15px);
    top: 20%;
    z-index: 1;
  }

  .box {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    gap: 20px;
    min-height: 48px;
    position: relative;

    .inputLabel {
      white-space: nowrap;
    }

    .checkbox {
      margin-bottom: 10px;
      transform: scale(1.5);
      accent-color: #28a745; 
    }

    .checkboxSlider {
      margin-bottom: 40px;
      transform: scale(1.5);
      accent-color: #28a745; 
    }

    .sliderContainer {
      display: flex;
      flex-direction: column;
      width: 100%;

      .inputSlider {
        margin-top: 20px;
        margin-bottom: -10px;
        color:rgba(0, 0, 0, 0.38);
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      }

      .markLabel {
        color: #7d7d7d;
      }
    }
    .dateFieldWrapper {
      display: flex;
      flex-direction: column;
      .labelPicker {
        color:rgba(0, 0, 0, 0.38);
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      }
      .datePickerWrapper {
        display: flex;
        flex-wrap: nowrap;
        .inputPicker {
          border-radius: 10px;
          padding: 5px;
          text-align: center;
        }
      }
    }
  }

  .btnContainer {
    display: flex;
    justify-content: space-between;
  }

  button {
    margin-top: 20px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}