div.cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.cell div {
  display: none;
}

div.cell:hover div {
  display: block;
  font-size: 120%;
  background-color: #d3d3d3;
  color: #111;
  position: absolute;
  padding: 1px 5px 2px 5px;
  box-shadow: 1px 1px 3px #222222;
  width: auto;
  z-index: 99999;
}


div.toProduct{
  margin-left: 20px;
  width: 70px;
}