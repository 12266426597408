.container {
  padding: 1rem;
  margin: 1rem;
  margin-top: 0;
  background-color: white;
  border-radius: 5px;
  z-index: 1;
  filter: drop-shadow(0 0 0.05rem grey);
  
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .status {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.8rem;
    
    p {
      margin: 0;
    }
  }

  
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem;
  margin-bottom: 0;
  border: none;
  filter: drop-shadow(0 0 0.05rem grey);
  @media (max-width: 475px) {
    margin-top: 40px;
  }

  button {
    min-height: 0;
  }

  div[class^="MuiTabs-root"] {
    min-height: 0;
  }
}

.console {
  border: 1px solid rgb(200,200,200);
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
  font-size: 0.75rem;
  color: grey;
  padding: 10px;
}

.oneBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.mainForm {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .buttonsBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    gap: 15px;
    
    .buttonsBoxLeft {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: row;
      gap: 15px;
    }
    
    .buttonsBoxRight {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: row;
      gap: 15px;
    }

    button {
      margin: 0;
      margin-top: 15px;
    }
  }

}
