.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.main {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  padding-left: 240px;
  transition: all 0.3s ease-in-out;
}

.main-expanded {
  @extend .main;
  padding-left: 0;
  transition: all 0.3s ease-in-out;
}
