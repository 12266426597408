.mainForm {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .price {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #26bd62;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: calc(50% - 15px);
    top: 20%;
    z-index: 1;
  }

  .box {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    gap: 20px;
    min-height: 48px;
    position: relative;

    .checkbox {
      margin-bottom: 10px;
      transform: scale(1.5);
      accent-color: #28a745;
    }

    .checkboxSlider {
      margin-bottom: 40px;
      transform: scale(1.5);
      accent-color: #28a745;
    }

    .sliderContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 100%;

      .inputSlider {
        margin-top: 0;
      }

      .markLabel {
        color: #7d7d7d;
      }
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        input {
          width: 50px;
          border: none;
        }
      }
    }
  }

  button {
    margin-top: 20px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
