.block {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  margin-bottom: 1rem;

  button:first-of-type {
    margin-right: 1rem;
  }
}
.avatar-inside {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 2rem;

  .change-btns {
    display: flex;
    gap: 3rem;
    align-items: center;
    margin: 2rem;
  }
}

.container {
  margin: 2rem 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar-btn-wrapper {
    position: relative;
    width: max-content;
  }

  .avatar-wrapper {
    .avatar-overlay {
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      top: 0;
      opacity: 0;
      pointer-events: none;
      transition: all ease 0.25s;
      cursor: pointer;
      z-index: 10;
    }

    &:hover,
    &:focus {
      .avatar-overlay {
        pointer-events: initial;
        opacity: 1;
        transition: all ease 0.25s;
      }
    }
  }

  .delete-img {
    position: absolute;
    top: 0;
    right: -0.5rem;
    cursor: pointer;
    transition: all ease 0.25s;

    &:hover,
    &:focus {
      transform: scale(1.1);
      transition: all ease 0.25s;
    }
  }

  input {
    display: none;
  }
}

.error-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

@media screen and (max-width: 575px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
  }
}
