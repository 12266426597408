%coloredLink {
  background: #597fb0;
  color: #ffffff;
}

.sidebar {
  font-size: 18px;
  position: fixed;
  height: 100%;
  z-index: 55555;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    padding: 10px 15px;
    display: block;
    font-weight: bold;
    color: #333333;
    transition: 0.3s;

    &:hover {
      text-decoration: none;
      @extend %coloredLink;
    }
  }

  .active {
    @extend %coloredLink;
  }
}

.icon {
  color: #26bd62;
}
