@import '../../values/colors.scss';
@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker__header {
  background-color: $primarygreen;
}

.react-datepicker__day--keyboard-selected {
  background-color: $primaryBlue;
}

.react-datepicker__current-month {
  color: $primarylight;
}

.react-datepicker__day-name {
  color: $primarylight;
}

.react-datepicker {
  border: none;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}

.react-datepicker-wrapper {
  margin-right: 15px;
}

.react-datepicker__day--selected {
  background-color: $primaryBlue;
}

.react-datepicker__day--selected:hover {
  background-color: $secondaryBlue;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: #000000;
}
