.container {
  padding: 1rem;

  .header-btn-wrapper {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
  }

  .discountInfo {
    display: flex;
    flex-wrap: wrap;

    .countContainer {
      &:last-child {
        margin-left: 6rem;
      }

      padding: 0 1rem;

      p {
        margin: 0 0 0.5rem 0;

        &:last-child {
          margin: 0;
        }
      }

      span {
        font-weight: bold;
      }

      @media (max-width: 1100px) {
        &:last-child {
          margin-left: 4rem;
        }
      }

      @media (max-width: 768px) {
        &:last-child {
          margin-left: 0;
        }
        p {
          &:last-child {
            margin: 0 0 0.5rem 0;
          }
        }
      }
    }
  }

  .discountInfo {
    display: flex;
    flex-wrap: wrap;

    .countContainer {
      &:last-child {
        margin-left: 6rem;
      }

      padding: 0 1rem;

      p {
        margin: 0 0 0.5rem 0;

        &:last-child {
          margin: 0;
        }
      }

      span {
        font-weight: bold;
      }
    }
  }
}
