.edit_quantity {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  input {
    width: 100%;
  }
}

.quantity {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 40%;
  max-width: max-content;
}

.orderHeader {
  padding-top: 20px;
  // display: flex;
  // flex-direction: column;
  // gap: 15px;
  @media (max-width: 475px) {
    margin-top: 30px;
  }
  &__price-discount-label {
    color: red;
    font-weight: bold;
    font-size: 21px;
  }
}

.headerUserData {
  font-size: 16px;
  // margin: 10px 0 10px 0;
}

.commentBlock {
  width: 60vw;
  // margin-top: 20px;
  // margin-bottom: 20px;
  font-size: 16px;
}

.headerTitle {
  // margin-top: 10px;
  font-size: 26px;
}

.amount {
  text-align: start;
  // margin-right: 8%;
  font-size: 16px;
}
.acceptCancel {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}
.message {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0 0 10px 5px rgba(44, 44, 44, 0.338);
  color: #fff;
  width: 300px;
  height: 50px;
  transition: all 0.3s ease;
}
div.cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.cell div {
  display: none;
}

div.cell:hover div {
  display: block;
  font-size: 120%;
  background-color: #d3d3d3;
  color: #111;
  position: absolute;
  padding: 1px 5px 2px 5px;
  box-shadow: 1px 1px 3px #222222;
  width: auto;
  z-index: 99999;
}

.notcall {
  display: inline;
  font-size: 16px;
  margin-left: -2px;
}

.remark {
  font-size: 12px;
  margin: 0;
}

.errorMessage {
  @extend .remark;
  color: red;
}

.formParcelNumber {
  font-size: 16px;
  margin-top: 6px;
  button {
    cursor: pointer;
  }
}

.headerUserDataWrapp {
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  .deleteIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #26bd62;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
      background: #26bd62;
      color: #fff;
      opacity: 0.7;
    }
  }
}

.ordersWrapp {
  position: relative;
}

.deleteBtn {
  display: block;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 99999;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    opacity: 0.7;
  }
}
