.sizesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  & > div {
    margin: 4px;
  }
}

.sizeInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  & > button {
    margin: 5px 10px;
    border-radius: 30px;
  }
}

.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
}
