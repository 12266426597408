.container {
  width: 100%;
  height: 92%;
  position: fixed;
  z-index: 10;

  .lightScreen {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0.3;
  }
}
