.expandBlock {
  height: auto;
  overflow: auto;
  box-shadow: none !important;

  .expandBlockArrow {
    cursor: pointer;
  }

  .treeHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;

    .title,
    .emptyTitle {
      cursor: default;
      color: gray;
      font-weight: 500;
      font-size: 17px;
      margin-left: 10px;
    }

    .forkIcon {
      display: inline-block;
      border-radius: 60px;
      box-shadow: 0px 0px 2px #888;
      padding: 0.5em 0.5em;
      color: #26bd62;
      font-size: 18px;
      margin-right: 10px;
    }

    .forkIconDark {
      display: inline-block;
      border-radius: 60px;
      box-shadow: 0px 0px 2px #888;
      padding: 0.5em 0.5em;
      color: #4A7C56;
      font-size: 18px;
      margin-right: 10px;
    }

    .emptyTitle {
      align-self: center;
    }

    .addSubBtn {
      margin-top: 20px;
      color: white;
      background-color: rgb(31, 116, 186);

      :first-child {
        margin-right: 10px;
      }
    }
  }
}

.children {
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
  }
}

.expandBlock-dark {
  @extend .expandBlock;

  background-color: inherit !important;
}
